<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link class="crumbs_item" tag="a" to="/admin/BasicConfig/CertificateTemplateList">证书模板 / </router-link>
          <span class="crumbs_item crumbs_last">新增模板</span>
        </span>
        <a-button type="primary" @click="onPreview(false)" :loading="previewLoad">证书预览</a-button>
      </div>
    </div>
    <div class="workspace-body" v-if="templateVisible">
      <!-- 证书显示区 -->
      <div class="body-left">
        <div class="certificate-viewer">
          <div class="infinite-viewer-wrapper"></div>
          <div class="template-horizontal" :style="{width: tempXLength + 'px', height: tempYLength + 'px'}">
            <div class="drag-list">
              <!-- contenteditable="true"  -->
              <div class="drag-item" 
                v-for="(item,index) in dragList" 
                :key="'drag' + index" 
                :style="item.styleObject"
                :ref="'refDrag' + index" 
                v-drag="item">{{item.textContent}}</div>
            </div>
            <img class="template-bg" v-show="CertificateImage" :src="CertificateImage" alt="">
            <div class="template-bg" v-show="!CertificateImage"></div>
          </div>
        </div>
      </div>
      <!-- 功能区 -->
      <div class="body-right">
        <div class="line-item">
          <a-input v-model="pictureName" placeholder="请输入证书名称" /> 
        </div>
        <div class="line-item">
          <div class="title">模板说明：</div>
          <a-textarea v-model="instructions" placeholder="请输入模板说明" /> 
        </div>
        <div class="line-item">
          <div class="title">证书背景：</div>
          <div class="title small-title">默认空白背景，可
            <div class="modify" @click="onclickImg">
              <span>上传自定义背景</span>
              <input
                type="file"
                ref="file"
                @change="preview"
                accept="image/jpg, image/png, image/jpeg"
                style="display: none"
              />
            </div>
          </div>
          <div class="bg-image" v-show="CertificateImage">
            <!-- <a-icon class="delete"  type="delete" @click="onDelImage" /> -->
            <img :src="CertificateImage" alt="">
          </div>
          <div v-show="CertificateImage" class="btn">
            <a-button @click="onclickImg">更换背景图</a-button>
          </div>
        </div>
        <div class="line-item">
          <div class="title">添加元素：</div>
          <a-button @click="txtShow = true">添加文字</a-button>
        </div>
        <div class="line-item">
          <a-button type="primary" :loading="createLoad" v-hasPermi="['certif:certiftemp:add']" @click="onSubmit">生成</a-button>
        </div>
        <div class="line-item">
          <div class="title">已添加元素：</div>
          <div class="add-list">
            <div class="add-item" v-for="(item,index) in dragList" :key="index">
              <span>{{index + 1}}、{{item.textContent}}</span>
              <a-icon class="delete" type="delete" @click="onItemDel(index)" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 预设证书模板尺寸 -->
    <a-modal
      v-model="plateShow"
      width="430px"
      title="选择证书尺寸"
      :maskClosable="false"
    >
      <div class="modal-dialog">
        <div class="certificate-style-item" @click="plateIndex = 0" :class="{'certificate-hover' : plateIndex == 0}">
          <img class="icon" v-show="plateIndex == 0" src="@/assets/image/dataAnalyse/horizontal.png" alt="">
          <img class="icon" v-show="plateIndex == 1" src="@/assets/image/dataAnalyse/horizontal-2.png" alt="">
          <p class="name">横板</p>
          <p class="size">3508*2480</p>
        </div>
        <div class="certificate-style-item" @click="plateIndex = 1" :class="{'certificate-hover' : plateIndex == 1}">
          <img class="icon" v-show="plateIndex == 0" src="@/assets/image/dataAnalyse/vertical.png" alt="">
          <img class="icon" v-show="plateIndex == 1" src="@/assets/image/dataAnalyse/vertical-2.png" alt="">
          <p class="name">竖版</p>
          <p class="size">2480*3508</p>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="onNext">下一步</a-button>
      </template>
    </a-modal>

    <!-- 预设证书配置字段 -->
    <a-modal
      v-model="txtShow"
      width="630px"
      title="请添加文字内容"
    >
      <div class="all_content_box">
        <a-textarea v-model="content" /> 
        <p class="info">以下是可拼接字段的填写说明</p>
        <div class="code-list">
          <div class="code-item" v-for="(item,index) in codeList" :key="index" @click="onAddCode(item)">
            <span class="name">{{item.name}}</span>
            <a-tooltip placement="bottom" :title="item.title">
              <span class="code" >{{item.code}} <a-icon v-show="item.title" type="question-circle" /></span>
            </a-tooltip>
          </div>
        </div>
        <template v-if="content=='{QRcode}' || content=='{photo}' || isCenter">
          <p class="info">{{content=='{QRcode}' ? '二维码' : content=='{photo}' ? '图片' : '文本'}}宽度设置</p>
          <div class="inner">
            <a-input-number v-model="qrcodeWidth" />
          </div>
        </template>
        <p class="info">换行设置</p>
        <div class="inner wrap">
          <div class="item">是否居中：<a-switch :disabled="isWrap" v-model="isCenter" /></div>
          <div class="item">是否换行：<a-switch :disabled="isCenter" v-model="isWrap" /></div>
          <div v-show="isWrap">设置 <a-input-number  v-model="wrapNum" /> 字符为一行</div>
        </div>
        <p class="info">文本内容颜色</p>
        <div class="inner color">
          <div class="select-color">
            已选取色：<i :style="{background:selectColor}"></i>
          </div>
          <div class="color-list">
			      <input type="color" :value="selectColor" @input="selectColors($event)">
            <div class="color-item" :style="{background:item}" @click="onSelectColor(item)" v-for="(item,index) in colorLst" :key="'color' + index"></div>
          </div>
        </div>
        <p class="info">文本内容字号</p>
        <div class="inner">
          当前字号：{{wordSize}}px
          <a-slider v-model="wordSize" :min="16" :max="120" />
        </div>
        <p class="info">建议字号</p>
        <div class="inner">
          <p>1、横版证书（如孤独症、智力类型）建议字号：80px；</p>
          <p>2、竖版证书建议字号：56px；</p>
          <p>3、横版证书建议字号：56px；</p>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="onCreateAdd">确认</a-button>
        <a-button @click="txtShow = false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      pictureId:0, // 证书id
      CertificateImage:'', // 证书背景图
      plateIndex: 0,
      pictureName:'', //证书名称
      instructions: '', //说明
      previewUrl:'', // 证书预览图
      templateVisible:false, // 模板
      plateShow: true, // 排版预设弹窗
      txtShow:false, // 文本弹窗
      previewLoad:false, // 预览加载
      createLoad:false, // 生成加载
      tempXLength: 3508, // 证书模板长
      tempYLength: 2480, // 证书模板高
      codeList:[
        { name:'姓名', code:'{name}' },
        { name:'性别', code:'{sex}' },
        { name:'生日', code:'{birthday}' },
        { name:'身份证号', code:'{idcard}' },
        { name:'证件类型', code:'{idTypeStr}' },
        { name:'学历', code:'{degree}' },
        { name:'职务', code:'{occupationName}' },
        { name:'工作单位', code:'{work}' },
        { name:'证书编号', code:'{code}' },
        { name:'发证日期', code:'{formatTime1}', title:'yyyy年MM月' },
        { name:'发证日期', code:'{formatTime2}', title:'yyyy-MM-dd' },
        { name:'发证日期', code:'{formatTime3}', title:'yyyy年MM月dd日' },
        { name:'头像', code:'{photo}' },
        { name:'二维码', code:'{QRcode}' },
        { name:'', code:'' },
      ],
      colorLst:['#777777','#999999','#FFFFFF','#FF4B50','#FF7144','#FF9E17','#FF7D95','#A06AD2','#1DC88C','#32ECE1','#81E3F9','#3A63F3','#3157DC'],
      dragList: [
        // {
        //   content: '名字',
        //   fontName: '宋体',
        //   fontSize: 36,
        //   colorR: 0,
        //   colorG: 0,
        //   colorB: 0,
        //   x:0,
        //   y:0,
        //   styleObject:{
        //     color: 'red',
        //     fontSize: 50,
        //   }
        // }
      ],

      // 创建文本表单
      content: '', //文本内容
      qrcodeWidth: 200, // 二维码宽度
      isCenter: false, // 是否居中
      isWrap: false, // 是否换行
      wrapNum: 0, // 换行字符
      selectColor: '#000000', // 字体颜色
      selectRgbColor: {r:0,g:0,b:0}, // 数值色值
      wordSize: 48, // 字号大小
    }
  },
  // 自定义指令 —— 拖拽div
  directives: {
    drag(el,item) {
      let oDiv = el // 当前元素
      // 禁止选择网页上的文字
      document.onselectstart = function () {
          return false
      }
      oDiv.onmousedown = function (e) {
        // 鼠标按下，计算当前元素距离可视区的距离
        let disX = e.clientX - oDiv.offsetLeft
        let disY = e.clientY - oDiv.offsetTop
        document.onmousemove = function (e) {
          // 通过事件委托，计算移动的距离
          let l = e.clientX - disX
          let t = e.clientY - disY
          // 移动当前元素
          oDiv.style.left = l + 'px'
          oDiv.style.top = t + 'px'
          clearTimeout(this.tiemID);
          this.tiemID = setTimeout(() => {
            // 获取配置字段坐标
            let lineX = oDiv.offsetLeft + 100
            let lineY = oDiv.offsetTop + 100 + item.value.fontSize
            item.value.xaxis = lineX
            item.value.yaxis = lineY
          }, 300);
        }
        document.onmouseup = function (e) {
          document.onmousemove = null
          document.onmouseup = null
        }
        // return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
        return false
      }
    },
  },
  // 事件处理器
  methods: {
    // 模板选择后处理
    onNext(){
      if(this.plateIndex == 1){
        this.tempXLength = 2480
        this.tempYLength = 3508
      }
      this.plateShow = false
      this.templateVisible = true
    },
    // 删除背景图
    onDelImage(){
      this.CertificateImage = ''
    },
    //点击图片唤起本地文件夹
    onclickImg() {
      this.$refs.file.value=null
      this.$refs.file.click();
    },
    //上传图片
    preview(e) {
      if (!this.$regular.imgReg.reg.test(e.target.files[0].name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      let _this = this;
      let file = e.target.files[0];
      if (!file) {
        return;
      }

      // 腾讯云对象存储上传文件
      const bucket = 'template/certificate/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      this.$message.loading({ content: "正在上传...", duration: 0 });
      this.$cosUpload(file, bucket, _this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          _this.CertificateImage = url;
          _this.$message.success("上传成功");
        } else {
          _this.$message.error("上传失败");
        }
      });

    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    onAddCode(e){
      if((e.code == '{QRcode}' && this.content) || (this.content && (this.content.indexOf('{QRcode}') != -1))){
        this.$message.error("二维码需要独占一个字段设置");
        return
      }
      if((e.code == '{photo}' && this.content) || (this.content && (this.content.indexOf('{photo}') != -1))){
        this.$message.error("证书头像需要独占一个字段设置");
        return
      }
      this.content = this.content + e.code
    },

    // 创建字段
    onCreateAdd(){
      if(!this.content){
        this.$message.error("请编辑内容");
        return
      }
      // 是否配置二维码
      var isQRcode = this.content.indexOf('{QRcode}') != -1

      // 是否配置头像
      var isPhoto = this.content.indexOf('{photo}') != -1

      if((isQRcode || isPhoto) && !this.qrcodeWidth){
        this.$message.error("请设置" + (isQRcode ? '二维码' : '头像') + "图片宽度");
        return
      }

      if(this.isWrap && !this.wrapNum){
        this.$message.error("请设置换行字符数");
        return
      }

      this.dragList.push({
        textContent: this.content, // 字段内容
        font: '楷体', // 设置字体
        fontSize: this.wordSize, // 设置字号
        colorR: this.selectRgbColor.r,
        colorG: this.selectRgbColor.g,
        colorB: this.selectRgbColor.b, 
        fieldsType: (isPhoto ? 3 : isQRcode ? 2 : 1), // 字段类型1.文字内存在填充字段 2.二维码字段 3.头像
        qrCodeSize: this.qrcodeWidth,
        fontStyle: 1, // 字体格式 0 普通 1 加粗 2斜体
        xaxis:0, // 横坐标
        yaxis:0, // 纵坐标
        center: (this.isCenter ? 1 : 0), // 是否居中 1.居中 0居左
        lineFeed: (this.isWrap ? 1 : 0), // 是否换行 1.换行 0不换
        wordCount: this.wrapNum, //每行字数
        styleObject:{ // 字体样式
          color: this.selectColor,
          fontSize: this.wordSize + 'px',
          lineHeight: this.wordSize + 'px',
          width: ((isPhoto || isQRcode || this.isCenter) ? this.qrcodeWidth +'px' : ''),
          height: (isPhoto ? ((this.qrcodeWidth / 0.75) +'px') : isQRcode ? (this.qrcodeWidth + 'px') : '' ),
          background: ((isPhoto || isQRcode || this.isCenter) ? 'red' : 'none'),
          textAlign: (this.isCenter ? 'center' : 'left'),
        }
      })


      // 内容预设框关闭,字段值重置
      this.txtShow = false
      this.content = ''
      this.qrcodeWidth = 200
      this.isCenter = false
      this.isWrap = false
      this.wrapNum = 0
      this.selectColor = '#000000'
      this.selectRgbColor = {r:0,g:0,b:0}
      this.wordSize = 48
    },
    // 删除添加元素
    onItemDel(index) {
      this.dragList.splice(index, 1);
    },
    // 自定义颜色选取
    selectColors(event){
      this.selectColor=event.target.value
      this.olorRgb(event.target.value)
    },
    // 色值转rgb
    olorRgb(color){
      var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
      var sColor = color.toLowerCase();
      if(sColor && reg.test(sColor)){
        if(sColor.length === 4){
          var sColorNew = "#";
          for(var i=1; i<4; i+=1){
            sColorNew += sColor.slice(i,i+1).concat(sColor.slice(i,i+1));	
          }
          sColor = sColorNew;
        }
        //处理六位的颜色值
        var sColorChange = [];
        for(var i=1; i<7; i+=2){
          sColorChange.push(parseInt("0x"+sColor.slice(i,i+2)));	
        }
        this.selectRgbColor = {r:sColorChange[0],g:sColorChange[1],b:sColorChange[2]}
      }else{
        return sColor;	
      }
    },
    // 选色
    onSelectColor(color){
      this.selectColor = color
      this.olorRgb(color)
    },
    // 证书生成预览
    onPreview(isCreate){
      if(!this.CertificateImage){
        this.$message.error("请上传证书背景图");
        this.createLoad = false
        return
      }
      this.previewLoad = true

      var dragList = JSON.parse(JSON.stringify(this.dragList))
      dragList.forEach(element => {
        element.styleObject = JSON.stringify(element.styleObject)
      });

      this.$ajax({
        url: "/hxclass-management/certiftemp/preview",
        method: "post",
        params: {
          fontLocation: dragList,
          pictureUrl: this.CertificateImage, // 证书背景图
          pictureWidth: this.tempXLength, // 证书长
          pictureHigh: this.tempYLength, // 证书宽
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.previewUrl = res.data
          if(isCreate){
            this.createImage()
          } else {
            window.open(res.data);
          }
        } else {
          this.createLoad = false
          this.$message.error(res.message);
        }
        this.previewLoad = false
      });
    },
    // 证书生成
    onSubmit(){
      if(!this.pictureName){
        this.$message.error("请填写证书名称");
        return
      }
      if(!this.CertificateImage){
        this.$message.error("请上传证书背景图");
        return
      }
      if(!this.dragList.length){
        this.$message.error("请添加图片配置");
        return
      }
      this.createLoad = true
      this.onPreview(true)
    },
    // 证书生成
    createImage(){

      var dragList = JSON.parse(JSON.stringify(this.dragList))
      dragList.forEach(element => {
        element.styleObject = JSON.stringify(element.styleObject)
      });


      this.$ajax({
        url: this.pictureId ? '/hxclass-management/certiftemp/updatePictureConfig' : "/hxclass-management/certiftemp/addPictureConfig",
        method: this.pictureId ? "put" : "post",
        params: {
          pictureId: this.pictureId ? this.pictureId : undefined,
          fontLocationList: dragList,
          pictureUrl: this.CertificateImage, // 证书背景图
          instructions: this.instructions,
          pictureWidth: this.tempXLength, // 证书长
          pictureHigh: this.tempYLength, // 证书宽
          pictureName: this.pictureName, // 证书名称
          previewUrl: this.previewUrl, // 证书预览图
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$router.go(-1)
        } else {
          this.$message.error(res.message);
        }
        this.createLoad = false
      });
    },

    getDetail(){
      this.$ajax({
        url: "/hxclass-management/certiftemp/getPictureDetails",
        method: "get",
        params: {
          pictureId: this.pictureId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let obj = res.data
          this.tempXLength = obj.pictureWidth
          this.tempYLength = obj.pictureHigh
          this.plateIndex = 0
          if(this.tempXLength == 2480){
            this.plateIndex = 1
          }
          this.templateVisible = true

          obj.fontLocationList.forEach(element => {
            element.styleObject = JSON.parse(element.styleObject)
            element.styleObject.left = (element.xaxis - 100) + 'px'
            element.styleObject.top = (element.yaxis - element.fontSize - 100) + 'px'
          });

          this.dragList = obj.fontLocationList

          this.instructions = obj.instructions
          this.CertificateImage = obj.pictureUrl
          this.pictureName = obj.pictureName
          this.previewUrl = obj.previewUrl
        }
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    if(this.$route.query.pictureId){
      this.pictureId = this.$route.query.pictureId
      this.plateShow = false
      this.getDetail()
    }
   },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.workspace-body{
  position: relative;
  height: calc(100% - 78px);
  padding-right: 270px;
  overflow: hidden;
  background: #f1f3f8;
  transition: right 500ms ease;
  .body-left{
    position: relative;
    border-radius: 4px;
    height: 100%;
    .certificate-viewer{
      width: 100%;
      height: 100%;
      visibility: visible;
      .infinite-viewer-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: auto;
        top: 0;
        left: 0;
        will-change: scroll-position;
      }
      .template-horizontal{
        transform-origin: 0px 0px;
        transform: translate3d(20px, 20px, 0px) scale(0.47);
        .template-bg{
          width: 100%;
          height: 100%;
          user-select: none;
          background: #ffffff;
        }
        .drag-list{
          position: absolute;
          left: 100px;
          top: 100px;
          .drag-item{
            width: 2000px;
            // line-height: 50px;
            position: absolute;
            font-size: 36px;
            text-align: left;
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  .body-right{
    position: absolute;
    padding: 20px;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    width: 270px;
    background: #ffffff;
    border: 1px solid #f1f3f8;
    box-shadow: -6px 0px 10px 0px rgba(39,39,74,0.02);
    transition: right 500ms ease;
    .line-item{
      margin-top: 20px;
      margin-bottom: 10px;
      .title{
        margin-bottom: 5px;
        height: 18px;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        color: #9090a4;
        line-height: 18px;
      }
      .small-title{
        font-size: 12px;
        .modify{
          color: @theme;
          display: inline-block;
          cursor: pointer;
        }
      }
      .bg-image{
        background: #F7F8FA;
        text-align: center;
        padding: 10px 0;
        position: relative;
        img {
          max-width: 200px;
        }
        .delete{
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
      }
      .btn{
        margin-top: 10px;
        text-align: center;
      }
      .add-list{
        .add-item{
          padding: 10px 0;
          border-bottom: 1px solid #eeeeee;
          >span{
            line-height: 18px;
            color: #333333;
          }
          .delete{
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
    .line-item:nth-child(1){
      display: block;
      margin-top: 0;
    }
  }
}
.modal-dialog{
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  .certificate-style-item{
    text-align: center;
    padding: 25px;
    width: 126px;
    height: 126px;
    border-radius: 5px;
    background: #f7f8fa;
    cursor: pointer;
    .icon{
      width: 28px;
      margin-bottom: 10px;
    }
    .name{
      color: #27274A;
      font-weight: 500;
    }
    .size{
      font-size: 12px;
      transform: scale(0.8);
      color: #9090a4;
      line-height: 17px;
    }
  }
  .certificate-hover{
    background: #EBF5FF;
  }
}
.all_content_box{
  .info{
    margin-top: 10px;
    color: #333333;
  }
  .code-list{
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #BEC2C4;
    border-top: none;
    .code-item{
      width: calc(100% / 3);
      border-top: 1px solid #BEC2C4;
      span{
        display: inline-block;
        line-height: 35px;
        font-size: 12px;
        text-align: center;
      }
      .name{
        width: 38%;
        background: #E7F8FC;
      }
      .code{
        width: 62%;
        cursor: pointer;
      }
    }
  }
  .inner{
    background: #F7F8FA;
    padding: 5px;
    border-radius: 5px;
    margin-top: 4px;
  }
  .wrap{
    display: flex;
    height: 42px;
    .item{
      line-height: 30px;
      margin-right: 30px;
    }
  }
  .color{
    .select-color{
      display: flex;
      margin-bottom: 10px;
      i{
        display: inline-block;
        background: red;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    .color-list{
      display: flex;
      justify-content: space-between;
      .color-item{
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }
  }
}
</style>
